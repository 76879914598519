import React from 'react'

export default function RightArrow({ hoverState = true }) {
    return (
        <svg className={['arrows', (hoverState ? 'hover--true' : ''),].join(' ')} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle className='circle' r="23.5" transform="matrix(-1 0 0 1 24 24)" stroke="#2E2723" />
            <mask id="path-2-inside-1_222_1237" fill="white">
                <path d="M26.7867 31.1837L27.5234 30.447L21.3375 24.275C21.3105 24.2389 21.2958 24.195 21.2958 24.1499C21.2958 24.1048 21.3105 24.0609 21.3375 24.0248L27.3844 17.9779L26.6477 17.2412L20.5869 23.288C20.3594 23.5172 20.2317 23.827 20.2317 24.1499C20.2317 24.4728 20.3594 24.7826 20.5869 25.0117L26.7867 31.1837Z" />
            </mask>
            <path className='arrow' d="M26.7867 31.1837L27.7696 32.1667L26.7889 33.1473L25.806 32.1689L26.7867 31.1837ZM27.5234 30.447L28.5053 29.463L29.4904 30.4459L28.5063 31.43L27.5234 30.447ZM21.3375 24.275L20.3557 25.2591L20.2852 25.1887L20.2255 25.1091L21.3375 24.275ZM21.2958 24.1499L19.9058 24.1499L21.2958 24.1499ZM21.3375 24.0248L20.2255 23.1908L20.2848 23.1117L20.3546 23.0419L21.3375 24.0248ZM27.3844 17.9779L28.3673 16.9949L29.3503 17.9779L28.3674 18.9608L27.3844 17.9779ZM26.6477 17.2412L25.6659 16.2571L26.6488 15.2765L27.6306 16.2582L26.6477 17.2412ZM20.5869 23.288L19.6004 22.3087L19.6051 22.304L20.5869 23.288ZM20.5869 25.0117L19.6062 25.9969L19.6004 25.9911L20.5869 25.0117ZM25.8038 30.2007L26.5405 29.464L28.5063 31.43L27.7696 32.1667L25.8038 30.2007ZM26.5416 31.431L20.3557 25.2591L22.3194 23.291L28.5053 29.463L26.5416 31.431ZM20.2255 25.1091C20.0178 24.8321 19.9058 24.4955 19.9058 24.1499L22.6859 24.1499C22.6859 23.8945 22.6032 23.6457 22.4496 23.441L20.2255 25.1091ZM19.9058 24.1499C19.9058 23.8037 20.0181 23.4673 20.2255 23.1908L22.4496 24.8589C22.6028 24.6546 22.6859 24.4058 22.6859 24.1499L19.9058 24.1499ZM20.3546 23.0419L26.4015 16.995L28.3674 18.9608L22.3205 25.0078L20.3546 23.0419ZM26.4015 18.9609L25.6648 18.2242L27.6306 16.2582L28.3673 16.9949L26.4015 18.9609ZM27.6295 18.2253L21.5687 24.2721L19.6051 22.304L25.6659 16.2571L27.6295 18.2253ZM21.5734 24.2674C21.6044 24.2362 21.6218 24.1939 21.6218 24.1499H18.8417C18.8417 23.4601 19.1144 22.7982 19.6004 22.3087L21.5734 24.2674ZM21.6218 24.1499C21.6218 24.1059 21.6044 24.0636 21.5734 24.0324L19.6004 25.9911C19.1144 25.5015 18.8417 24.8397 18.8417 24.1499H21.6218ZM21.5676 24.0266L27.7674 30.1986L25.806 32.1689L19.6062 25.9969L21.5676 24.0266Z" fill="#ff6326 " mask="url(#path-2-inside-1_222_1237)" />
        </svg>
    )
}
