import React, { useEffect } from 'react'


export default function ScalingBanner({ children, image, svg, video, hide = false }) {

    useEffect(() => {
        const interval = setInterval(() => {
            var percent = 1 - ((window.pageYOffset / document.querySelector('.hero--banner')?.offsetHeight) * .85);
            if (document.querySelector('.opacity--wrapper')) {
                document.querySelector('.opacity--wrapper').style.opacity = percent;
            }
        }, 100)
        return () => clearInterval(interval)
    }, [])

    return (
        <div className='w100 h100vh-r flex ju-c al-i-c bg-wrapper hero--banner'>
            <div className=' z-2 w100 scaling--banner--wrapper flex '>

                <div className='flex ma'>
                    <div className={'scaling--banner flex  greater-5 flex flex-c'}>

                        {svg}
                        <div className='m-hide'>
                            {children}
                        </div>


                    </div>
                </div>

            </div>
            <div className="bg-image bg-black">
                <div className="opacity--wrapper h100">
                    {(video ?
                        <>
                            <video className="bg-video m-hide z-2" muted autoPlay loop><source src={video} type="video/mp4" /></video>
                            <div className='bg-image bg-parallax z-1' style={{ backgroundImage: (image ? `url(${image.url})` : `url('./bush.jpeg')`) }}></div>
                        </>
                        : <div className='bg-image bg-parallax z-1' style={{ backgroundImage: (image ? `url(${image.url})` : `url('./bush.jpeg')`) }}></div>
                    )}
                </div>
            </div>
            <div className='mouse'>
                <div className='m-hide'>
                    <div className='icon m-hide'><div className='inner'></div></div>
                </div>
                <div className='m-show'>
                    {hide ? null : children}
                </div>
            </div>
        </div>
    )
}
